<template lang="pug">
div(class='Subscribe')
	div(class='Wrapper')
		div(v-if='variablesList.socialButtonsTitle', class='Title') {{ variablesList.socialButtonsTitle }}
		div(class='SocialButtons')
			template(v-for='social in socialsState', :key='social.id')
				NuxtLink(
					v-if='social.link && social.icon',
					class='Social',
					:href='social.link',
					:title='social.title',
					target='_blank'
				)
					UiIcon(:name='social.icon', size='lg')
</template>

<script lang="ts" setup>
/**
 * Define hooks
 */
const { variablesList, fetchVariables } = useVariables();
const { socialsState, fetchSocials } = useSocials();

/**
 * Define fetch
 */
await fetchVariables();
await fetchSocials();
</script>

<style lang="less" scoped>
.Subscribe {
	padding: 1.875rem 0;
	.box(grid; 100%; auto; @ColorWhite; 0; auto; 1fr; stretch; start);
	& > .Wrapper {
		margin: 0 auto;
		padding: @ContainerPadding;
		max-width: @ContainerWidth;
		.box(grid; 100%; auto; none; 1rem; auto auto; 1fr; stretch; start);
		& > .Title {
			.grid(1 span);
			.text(@ColorBase; 1.25em 1.875rem @bold);
		}
		& > .SocialButtons {
			.grid(1 span);
			.text(@ColorBase; 1.125rem 1.125rem @medium);
			.box(grid; auto; auto; none; 1.875rem; 1fr; auto; center; start; column);
			& > .Social {
				.text(@ColorWhite);
				.border-radius(@BorderRadiusFull);
				.box(grid; 3.125rem; 3.125rem; @ColorBase; 0; 1fr; 1fr; center; center);
				.transition(all; 250ms; 'sine');
				&:hover {
					.background(@ColorPrimary);
				}
			}
		}

		@media all and (min-width: @tablet) {
			padding: 0 3.125rem;
			.box(grid; 100%; auto; none; 1rem; auto; 1fr auto; center; start);
		}
	}
}
</style>
